import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiEndpoints } from 'src/app/core/config/api-endpoints.config';
import { environment } from 'src/environments/environment';
import { Category, CategoryPayload } from '../models/category';
import { EventService } from 'src/app/shared/services/event.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeofenceService {

  url: any = environment.loginUrl;
  endPoints = ApiEndpoints.settings.Geofence;
  http = inject(HttpClient);
  headers = new HttpHeaders({
    'spinner': 'false'
  });

  constructor( private serviceEvent: EventService) { }

  getAllCategories () {
    const url = `${this.url}${this.endPoints.getCategories}`;
    return this.http.get<any>(url, {headers: this.headers});
  }

  addCategory(payload: CategoryPayload, HeadersObj: { spinner: string; }) {
    const url = `${this.url}${this.endPoints.addCategory}`;
    return this.http.post<Category>(url, payload, {headers: this.headers});
  }

  editCategory(payload: Category) {
    const url = `${this.url}${this.endPoints.editCategory}`;
    return this.http.put<Category>(url, payload, {headers: this.headers});
  }

  deleteCategory(id: number) {
    const url = `${this.url}${this.endPoints.deleteCategory}${id}`;
    return this.http.delete<any>(url, {headers: this.headers});
  }

  getGeofenceByFloor(id: number) {
    const url = `${this.url}${this.endPoints.getGeofenceByFloor}${id}`;
    return this.http.get<any>(url);
    // return of( 
    //   [
    //     {
    //       "id": 1,
    //       "floorId": 44,
    //       "name": "Ungrouped79",
    //       "categoryId": 1,
    //       "type": "polygon",
    //       "color": "blue",
    //       "coordinates":[
    //               [27.371767300523047,  22.956164070021998],
    //               [27.21555620902969, 40.54476615154052],
    //               [9.01530233342059, 40.36888013072534],
    //               [9.015302333420598, 3.081043717906096],
    //               [20.2209657795223, 3.081043717906096],
    //               [20.46818922264095, 18.119298497604433]
    //             ],
    //       "parentId": null
    //     },
    //     {
    //       "id": 2,
    //       "floorId": 44,
    //       "name": "Ungrouped-1-1",
    //       "categoryId": 1,
    //       "type": "rectangle",
    //       "color": "red",
    //       "coordinates":[
    //               [11.26461221250444, 17.239868393528514],
    //               [16.804541076383455, 17.239868393528514],
    //               [16.804541076383455, 32.62989521485718],
    //               [11.26461221250444, 32.62989521485718]
    //             ],
    //       "parentId": 1
    //     },
    //     {
    //       "id": 3,
    //       "floorId": 44,
    //       "name": "Storage",
    //       "categoryId": 79,
    //       "type": "circle",
    //       "color": "green",
    //       "radius": 500180,
    //       "coordinates":[29.9, 17.40],
    //       "parentId": null
    //     },
    //     {
    //       "id": 4,
    //       "floorId": 44,
    //       "name": "Office",
    //       "categoryId": 94,
    //       "type": "polyline",
    //       "color": "blue",
    //       "coordinates":[
    //               [25.562265014427492, 7.126212655024991],
    //               [22.755920681486405, 15.920513695784251],
    //               [26.58852714730864, 22.955954528391633],
    //               [28.459033019728068, 16.096399716599436],
    //               [20.2209657795223, 3.081043717906096]
    //             ],
    //       "parentId": null
    //     }
        
    //   ])
  }

  addGeofence(payload: any) {
    const url = `${this.url}${this.endPoints.addGeofence}`;
    return this.http.post<any>(url, payload);
  }

  deleteGeofence(id: number) {
    const url = `${this.url}${this.endPoints.deleteGeofence}${id}`;
    return this.http.delete<any>(url);
  }

  editGeofence(id: any, payload: any) {
    const url = `${this.url}${this.endPoints.editGeofence}${id}`;
    return this.http.post<Category>(url, payload);
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
import { ConfirmationService } from 'primeng/api';
import { WidgetsComponent } from 'src/app/shared/ui-components/widgets/widgets.component';
import { GeofenceService } from './services/geofence.service';
import { Category } from './models/category';
import { HeadersObj } from 'src/app/shared/constants/constants';
import { categoryFieldConfig } from './services/add-categorey-type-constant';
import { GeofenceListingComponent } from 'src/app/shared/ui-components/geofence-listing/geofence-listing.component';

import { ActionButtons, Geofence, GeofenceCategory } from './geofence.interface';
import { catchError, map, of } from 'rxjs';
// Override the `readableArea` method in Leaflet Draw
(L as any).GeometryUtil.readableArea = function () {
  return ''; // Returning an empty string disables the area calculation display
};
@Component({
  selector: 'app-geofencing',
  templateUrl: './geofencing.component.html',
  styleUrls: ['./geofencing.component.scss']
})
export class GeofencingComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild(WidgetsComponent) selectedEditRow!: WidgetsComponent;
  @ViewChild('form') form!: any;
  @ViewChild(GeofenceListingComponent) geoListComp!: GeofenceListingComponent;

  @Input() floorData: any;
  @Output() backToFloor = new EventEmitter<any>()

  tableConfig: any;
  btnText = "Save";
  geofences: any[] = []; // Array to store geofences in hierarchical format

  actionButtons: ActionButtons = {
    toggle: false,
    edit: true,
    delete: true,
    screenType: 'create-geofence'
  }

  categoryConfig = categoryFieldConfig;
  map!: L.Map;
  drawnItems!: L.FeatureGroup;

  currentImageLayer!: L.ImageOverlay;
  baseMapLayer!: L.TileLayer;
  geofenceName = '';
  cloneName = '';
  geofenceColor = '#3388ff'; // Default color
  cloneColor = '';
  originalGeofenceState: any = {};

  categories!: Category[];
  selectedCategory!: any;

  displayCategoryDialog: boolean = false;
  category!: Category;
  currentGeofence: any;
  lastHighlightedGeofence: any = [];

  // Variable to track the currently editing geofence
  currentlyEditingGeofence: any = null;
  plottingGeofence: boolean = false;


  constructor(private confirmationService: ConfirmationService, private geofenceService: GeofenceService) {
  }

  ngOnInit(): void {
    this.getallCategories();
    this.initializeMap();
    this.onEditDetail(this.floorData);
  }

  //method for getting all the categories
  getallCategories() {
    this.geofenceService.getAllCategories().subscribe({
      next: ((res: any) => {
        this.categories = [...res.data];
        this.createTableConfig(this.categories);
        this.getGeofence(this.floorData.id);
        this.selectedCategory = this.categories.find((item: Category) => item.name == 'Ungrouped');

      }),
      error: ((error: any) => {
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Action Failed' })
      })
    })
  }

  // To set image on the map view..
  onEditDetail(floor: any) {
    const imageUrl = floor.image;

    if (this.currentImageLayer) {
      this.map.removeLayer(this.currentImageLayer);
    }

    if (this.baseMapLayer) {
      this.map.removeLayer(this.baseMapLayer);
    }

    const imageBounds: any = [
      [0, 0], // Southwest corner
      [floor.dy_position, floor.dx_position] // Northeast corner
    ];

    this.currentImageLayer = L.imageOverlay(imageUrl, imageBounds).addTo(this.map);
    this.map.fitBounds(this.currentImageLayer.getBounds());
    // this.map.setZoom(5);
  }

  goBack() {
    this.backToFloor.emit();
  }

  saveCategory(formConfig: any) {
    let payload: GeofenceCategory = {
      ...formConfig?.value
    }
    if (formConfig.invalid) {
      formConfig.markAllAsTouched();
    } else {
      if (this.btnText == 'Save') {
        this.geofenceService.addCategory(payload, HeadersObj).subscribe({
          next: ((res: any) => {
            this.categories.push(res.data[0]);
            this.tableConfig.data.push(this.createTableData(res.data[0]));
            this.form.form.reset({
              name: '',
              description: ''
            });
          })
        })
        // this.categories.push(payload);
      } else if (this.btnText = 'Update') {
        payload['id'] = this.category.id;
        this.geofenceService.editCategory(payload).subscribe({
          next: ((res: any) => {
            let index = this.categories.findIndex((item: Category) => item.id == this.category.id);
            this.categories.splice(index, 1, res.data[0]);
            this.tableConfig.data.splice(index, 1, this.createTableData(res.data[0]));
            this.onEditCategory(this.category);
          })
        })
      }
    }
  }

  createNewCategory() {
    this.displayCategoryDialog = true;
  }

  deleteSelectedGeofence(data: any) {
    this.confirmationService.confirm({
      header: 'Alert',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure that you want to delete the selected geofence',
      accept: () => {
        this.geofenceService.deleteGeofence(data.geofence.id).subscribe(res => {
          const category = this.geofences.find((g: any) => g.id === data.categoryId);
          if (category) {
            // Check if the geofence is a child of another geofence
            if (data.geofence.parentId) {
              const parentGeofence = category.geofences.find((g: any) => g.id === data.geofence.parentId);
  
              if (parentGeofence) {
                // Remove the geofence from the parent's children
                parentGeofence.children = parentGeofence.children.filter((child: any) => child.id !== data.geofence.id);
              }
            } else {
              // Remove the geofence directly from the category's geofences
              data.geofence?.children.forEach((geofence: any) => {
                this.drawnItems.removeLayer(geofence.layer);
              })
              category.geofences = category.geofences.filter((g: any) => g.id !== data.geofence.id);
            }
            this.drawnItems.removeLayer(data.geofence.layer);
          }
        })
      },
      reject: () => {
        return;
      }
    });
  }

  createTableConfig(category: any) {
    const headers = [
      { header: 'Name', field: 'name' },
      { header: 'Description', field: 'description' },
      { header: 'Actions', field: 'action' }
    ];
    const data: any = [];
    category.forEach((element: any) => {
      let config = this.createTableData(element);
      data.push(config);
    });
    this.tableConfig = { header: headers, data: data };
  }

  createTableData(element: Category) {
    const actionCheck = ((name: any) => name !== "Ungrouped")
    let config = {
      item: element,
      name: element.name,
      description: element.description,
      action: { edit: actionCheck(element.name), delete: actionCheck(element.name) }
    }
    return config;
  }

  onDeleteCategory(id: number) {
    this.confirmationService.confirm({
      header: 'Alert',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure that you want to delete the selected category',
      accept: () => {
        this.geofenceService.deleteCategory(id).subscribe({
          next: ((res: any) => {
            let index = this.categories.findIndex((item: Category) => item.id == id);
            this.categories.splice(index, 1);
            this.tableConfig.data.splice(index, 1)
          })
        })
      },
      reject: () => {
        return;
      }
    });
  }

  onEditCategory(data: any) {
    if (this.selectedEditRow.selectedRowIndex === data.id) {
      this.selectedEditRow.selectedRowIndex = -1;
      this.form.form.reset({
        name: '',
        description: ''
      });
      this.btnText = "Save"
    } else {
      this.selectedEditRow.selectedRowIndex = data.id;
      this.btnText = "Update"
      this.category = { ...data }
      this.form.form.controls['name'].setValue(data.name);
      this.form.form.controls['description'].setValue(data.description);
    }
  }

  onHide(event: any) {
    this.selectedEditRow.selectedRowIndex = -1;
    if (this.selectedCategory.name != 'Ungrouped') {
      let currentCategory = this.categories.find((cat: Category) => cat.id == this.selectedCategory.id);
      if (!currentCategory) this.selectedCategory = this.categories[0];
    }
    this.form.form.reset({
      name: '',
      description: ''
    });
    this.btnText = "Save"
  }

  // Helper to create a geofence with layer details
  async createGeofence(layer: any, boundsOrCenter: any, event?: any, radius?: number, checkIfCopy: boolean = false) {
    let geoName = '';
    const selectedCategory = this.geofences.find((category: any) => category.id === this.selectedCategory.id);
    let largestNumber = 0;

    if (selectedCategory?.geofences) {
      const geofencesWithHyphen = selectedCategory.geofences.flatMap((geo: any) => {
        const hasHyphenInName = geo.name.includes('-');
        const childGeofences = geo.children?.filter((child: any) => child.name.includes('-')) || [];
        return hasHyphenInName || childGeofences.length ? [geo, ...childGeofences] : [];
      });

      const numbers = new Set<number>();
      geofencesWithHyphen.forEach((geo: any) => {
        const numPart = parseInt(geo.name.split('-').pop(), 10);
        if (!isNaN(numPart)) numbers.add(numPart);
      });

      largestNumber = Math.max(0, ...numbers); // Get the largest number
    }
    const newGeofence: any = {
      id: event?.geoId || '',
      name: geoName,
      color: event?.geoColor ? event?.geoColor : (this.cloneColor ? this.cloneColor : this.geofenceColor),
      draggable: true,
      layer: layer,
      boundaries: radius
        ? { center: boundsOrCenter, radius: radius }
        : {
          xMin: boundsOrCenter.getSouthWest().lng,
          yMin: boundsOrCenter.getSouthWest().lat,
          xMax: boundsOrCenter.getNorthEast().lng,
          yMax: boundsOrCenter.getNorthEast().lat
        },
      parentId: null,
      children: [],
      expanded: false
    };
    const parentGeofence = this.getParentGeofence(newGeofence);
    if (parentGeofence) {
      const parentNumber = parseInt(parentGeofence.name.split('-').pop(), 10) || 0;
      const existingChildren = parentGeofence.children || [];
      const childNumbers = existingChildren.map((child: any) => parseInt(child.name.split('-').pop(), 10) || 0);
      const nextChildNumber = Math.max(...childNumbers, 0) + 1;

      geoName = this.cloneName
        ? this.cloneName
        : this.geofenceName
        || `${this.selectedCategory.name} - ${parentNumber}-${nextChildNumber}`;
    } else {
      geoName = this.cloneName
        ? this.cloneName
        : this.geofenceName
        || `${this.selectedCategory.name} - ${largestNumber + 1}`;
    }
    newGeofence.name = geoName.trim();
    if (this.plottingGeofence || checkIfCopy) {
      return newGeofence
    }
    else {
      return await this.saveGeofence(newGeofence, newGeofence.layer, parentGeofence);
    }
  }

  //to get coordinates
  getCoordinates(layer: any) {
    let coordinates: any[][] = [];
    if (this.getLayerType(layer) == 'circle') {
      let points = [];
      points.push(layer._latlng.lat);
      points.push(layer._latlng.lng);
      coordinates.push(points)
    } else if(this.getLayerType(layer) == 'polyline') {
      layer._latlngs.forEach((item: any) => {
        let points = [];
        points.push(item.lat);
        points.push(item.lng);
        coordinates.push(points)
      });    
    } else {
      layer._latlngs[0].forEach((item: any) => {
        let points = [];
        points.push(item.lat);
        points.push(item.lng);
        coordinates.push(points)
      });
    }
    return coordinates
  }

  // Handle adding geofences
  toAddGeofence() {
    this.map.on(L.Draw.Event.CREATED, (event: any) => {

      this.geoFenceListing(event, this.selectedCategory);
    });
  }

  async geoFenceListing(event: any, selectedCategory: any) {
    const layer = event.layer;
    let newGeofence: any;

    if (event.layerType === 'circle') {
      const radius = layer.getRadius();
      const latlng = layer.getLatLng();
      newGeofence = await this.createGeofence(layer, latlng, event, radius, );
    } else if (event.layerType === 'rectangle' || event.layerType === 'polygon' || event.layerType === 'polyline') {
      const bounds = layer.getBounds();
      newGeofence = await this.createGeofence(layer, bounds, event);
    }
    this.handleGeofenceCreation(newGeofence, selectedCategory);
    this.attachContextMenu(newGeofence.layer, event.layerType); // Attach context menu to the layer
  }


  getParentGeofence(newGeofence: any, checkIfCopy: boolean = false) {
    let parentGeofence: any = null;
    for (const category of this.geofences) {
      for (const geofence of category.geofences) {
        if (this.isGeofenceInside(geofence, newGeofence, category.id) && !checkIfCopy) {
          parentGeofence = geofence;
          break;
        }
      }
    }
    return parentGeofence;
  }

  // Function to check if newGeofence is inside parentGeofence (bounding box check)
  isGeofenceInside(parentGeofence: Geofence, newGeofence: Geofence, categoryId: any): boolean {
    const parentBounds = parentGeofence.boundaries;
    const newBounds = newGeofence.boundaries;

    return (
      categoryId == this.selectedCategory.id &&
      newBounds.xMin >= parentBounds.xMin &&
      newBounds.yMin >= parentBounds.yMin &&
      newBounds.xMax <= parentBounds.xMax &&
      newBounds.yMax <= parentBounds.yMax
    );
  }

  // Handle geofence creation logic (whether it's inside another or not)
  handleGeofenceCreation(newGeofence: any, category: any, checkIfCopy: boolean = false) {

    let parentGeofence: any = this.getParentGeofence(newGeofence, checkIfCopy);
    let existingCategory = this.geofences.find(cat => cat.id === category.id);
   
    if (parentGeofence) {
      parentGeofence.class = 'sub-child';
      parentGeofence.children.push(newGeofence);
      newGeofence.parentId = parentGeofence.id;
      existingCategory.expanded = true;
      parentGeofence.expanded = true;
    } 
    else {
      if (existingCategory) {
        existingCategory.geofences.push(newGeofence);
        existingCategory.expanded = true;
      } else {
        this.geofences.push({
          id: category.id,
          name: category.name,
          description: category.description,
          geofences: [newGeofence],
          expanded: true,
        });
      }
    }
    newGeofence.layer.setStyle({ color: newGeofence.color });
    newGeofence.layer.bindTooltip(newGeofence.name, {
      permanent: true,
      position: 'center'
    }).openTooltip();
    this.drawnItems.addLayer(newGeofence.layer);
    this.geofenceName = '';
    this.plottingGeofence = false;
  }

  // Function to copy geofence geometries with an offset
  async copyGeofence(originalLayer: any) {
    originalLayer.openTooltip();
    const offsetDistance = 0.0005; // Small offset to avoid exact overlap
    const baseName = originalLayer._tooltip._content + " - Copy";

    // Use the helper function to get a unique geofence name
    this.cloneName = this.getUniqueGeofenceName(baseName, this.geofences);
    this.cloneColor = originalLayer.options.color;
    let copiedLayer: any;
    let type: string = this.getLayerType(originalLayer); // Get the layer type

    let newLatLngs: any;

    // Create the copied layer based on type
    switch (type) {
      case 'circle':
        const newCenter = L.latLng(
          originalLayer.getLatLng().lat + offsetDistance,
          originalLayer.getLatLng().lng + offsetDistance
        );
        copiedLayer = L.circle(newCenter, {
          radius: originalLayer.getRadius(),
          opacity: originalLayer.options.opacity,
        }).addTo(this.map);
        break;
      case 'rectangle':
        // Copy the layer with an offset
        newLatLngs = this.offsetLatLngs(originalLayer.getLatLngs(), offsetDistance);
        copiedLayer = L.rectangle(newLatLngs, {
          opacity: originalLayer.options.opacity,
        }).addTo(this.map);
        break;
      case 'polygon':
        // Copy the layer with an offset
        newLatLngs = this.offsetLatLngs(originalLayer.getLatLngs(), offsetDistance);
        copiedLayer = L.polygon(newLatLngs, {

        }).addTo(this.map);
        break;
      case 'polyline':
        newLatLngs = this.offsetLatLngs(originalLayer.getLatLngs(), offsetDistance);
        copiedLayer = L.polyline(newLatLngs, {
          opacity: originalLayer.options.opacity,
        }).addTo(this.map);
        break;
    }

    // Bind the copied layer to tooltip and handle geofence creation
    if (copiedLayer) {
     
      this.attachContextMenu(copiedLayer, type);
      copiedLayer.name = this.cloneName;
      this.enableGeofenceEditing(copiedLayer, true);
    }
  }

  // Helper function to determine the layer type
  getLayerType(layer: any): string {
    if (layer instanceof L.Circle) return 'circle';
    if (layer instanceof L.Rectangle) return 'rectangle';
    if (layer instanceof L.Polygon) return 'polygon';
    if (layer instanceof L.Polyline) return 'polyline';
    return '';
  }

  // Helper function to offset lat/lngs for geometries (handles both polyline and polygon cases)
  offsetLatLngs(latlngs: any, offsetDistance: number): any[] {
    if (!Array.isArray(latlngs)) {
      return []; // Ensure latlngs is an array
    }

    // Check if the latlngs is an array of arrays (for polygons) or a flat array (for polylines)
    if (Array.isArray(latlngs[0])) {
      // Handle nested arrays for polygons (array of rings)
      return latlngs.map((latlngArray: any) =>
        latlngArray.map((latlng: any) => L.latLng(latlng.lat + offsetDistance, latlng.lng + offsetDistance))
      );
    } else {
      // Handle flat array for polylines
      return latlngs.map((latlng: any) => L.latLng(latlng.lat + offsetDistance, latlng.lng + offsetDistance));
    }
  }

  // Function to check for existing geofence names and generate a unique name
  getUniqueGeofenceName(baseName: string, geofences: any[]): string {
    let count = 1;
    let uniqueName = baseName;

    const checkGeofenceName = (geofence: any) => {
      if (geofence.name.startsWith(uniqueName)) {
        uniqueName = baseName + ` (${++count})`;
      }

      // Check in nested children if present
      if (geofence.children && geofence.children.length) {
        geofence.children.forEach((childGeofence: any) => {
          checkGeofenceName(childGeofence); // Recursively check child geofences
        });
      }
    };

    // Iterate through the geofences to find duplicates and adjust the name
    geofences.forEach((geofenceGroup: any) => {
      geofenceGroup.geofences.forEach((geofence: any) => {
        checkGeofenceName(geofence); // Check for name conflicts
      });
    });

    return uniqueName; // Return the final unique name
  }

  highlightGeofence(geofenceGroup: any) {
    // Reset the last highlighted geofences to their original colors
    this.lastHighlightedGeofence.forEach((geofence: any) => {
      (geofence.layer as any).setStyle({ color: geofence.color });
    });

    let lastGeofences: any[] = [];

    // Check if geofenceGroup is an object (single geofence) or an array of geofences
    if (typeof geofenceGroup === 'object' && !Array.isArray(geofenceGroup.geofences)) {
      // Highlight single geofence
      if (!this.lastHighlightedGeofence.includes(geofenceGroup)) {
        (geofenceGroup.layer as any).setStyle({ color: '#ff7800' }); // Highlight color
        this.map.fitBounds(geofenceGroup.layer.getBounds()); // Fit map to bounds of the geofence
        lastGeofences = [geofenceGroup]; // Store with set color
      }
      else {
        this.map.fitBounds(this.currentImageLayer.getBounds());
      }
    } else if (Array.isArray(geofenceGroup.geofences)) {
      if (this.lastHighlightedGeofence.length == 1) {
        this.lastHighlightedGeofence = []
      }
      // Highlight multiple geofences
      geofenceGroup.geofences.forEach((geofence: any) => {
        this.drawnItems.eachLayer((layer: L.Layer) => {
          if (geofence.layer === layer && !this.lastHighlightedGeofence.includes(geofence)) {
            lastGeofences.push(geofence); // Store with set color
            (geofence.layer as any).setStyle({ color: '#ff7800' }); // Highlight color
          }
        });
      });
    }

    // Update the last highlighted geofences
    this.lastHighlightedGeofence = lastGeofences;
  }

  initializeMap() {
    this.map = L.map('map', {
      zoom: 2,
    });

    this.baseMapLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);

    if (this.baseMapLayer) {
      this.map.addLayer(this.baseMapLayer);
    }
    this.drawnItems = new L.FeatureGroup();
    this.map.addLayer(this.drawnItems);

    const drawControl = new L.Control.Draw({
      edit: {
        edit: false,
        featureGroup: this.drawnItems,
        remove: false
      },
      draw: {
        circlemarker: false,
        marker: false,
        polygon: {
          showArea: true,
          shapeOptions: {
            color: 'purple'
          }
        },
        rectangle: {
          shapeOptions: {
            color: 'blue'
          }

        },
        circle: {
          shapeOptions: {
            color: 'green'
          }
        }
      }
    });
    this.map.addControl(drawControl);
    this.toAddGeofence();
  }

  // Attach context menu to a layer
  attachContextMenu(layer: any, type: string) {
    layer.on('contextmenu', (e: any) => {
      this.showContextMenu(e, layer, type);
    });
  }

  // Show custom context menu
  showContextMenu(event: any, geofenceLayer: any, type: string) {
    // Create the menu container if it doesn't exist
    let menu = document.getElementById('custom-context-menu');
    if (!menu) {
      menu = document.createElement('div');
      menu.id = 'custom-context-menu';
      menu.className = 'geofence-context-menu'
      document.body.appendChild(menu);
    }

    // Position the context menu at the mouse position
    menu.style.left = `${event.originalEvent.pageX}px`;
    menu.style.top = `${event.originalEvent.pageY}px`;

    // Clear the previous menu items
    menu.innerHTML = '';

    // Add "Edit" option to the context menu
    // const editOption = document.createElement('div');
    // editOption.innerHTML = '<i class="pi pi-pen-to-square"></i> Edit Geofence';
    // editOption.className = 'geofence-context-menu-items';
    // editOption.onclick = () => {
    //   this.hideContextMenu(); // Hide the context menu after selection
    // };
    // menu.appendChild(editOption);

    // Add "Copy" option conditionally based on the geofence type
    // if (geofenceLayer && (type === 'circle' || type === 'rectangle')) {
    const copyOption = document.createElement('div');
    copyOption.innerHTML = '<i class="pi pi-clone"></i> Copy Geofence';
    copyOption.className = 'geofence-context-menu-items';
    copyOption.onclick = () => {
      this.copyGeofence(geofenceLayer); // Copy the selected geofence
      this.hideContextMenu(); // Hide the context menu after selection
    };
    menu.appendChild(copyOption);

    // Option to close the menu
    const closeOption = document.createElement('div');
    closeOption.innerHTML = '<i class="pi pi-times"></i> Close Menu';
    closeOption.className = 'geofence-context-menu-items';
    closeOption.onclick = () => {
      this.hideContextMenu();
    };
    menu.appendChild(closeOption);
  }

  // Hide the context menu
  hideContextMenu() {
    const menu = document.getElementById('custom-context-menu');
    if (menu) {
      menu.style.left = '-9999px'; // Move it off-screen
    }
  }

  onEditGeofence(geofence: any) {
    if (this.geoListComp.currentGeofence == null || this.geoListComp.currentGeofence.id !== geofence.geofence.id) {
      this.geoListComp.currentGeofence = geofence.geofence;
      this.geofenceName = geofence.geofence.name;
      this.selectedCategory = this.categories.find((cat: Category) => cat.id == geofence.category.id);
      if(this.currentlyEditingGeofence){
        this.currentlyEditingGeofence?.editing?.disable();
        this.cancelGeofenceChanges(this.currentlyEditingGeofence);
        this.currentlyEditingGeofence = null;
      }
      this.enableGeofenceEditing(geofence.geofence.layer); // Enable editing mode
    } else if (this.geoListComp.currentGeofence.id == geofence.geofence.id) {
      const payload: any = {
        name: this.geofenceName.trim() ? this.geofenceName : 'Default',
        floorId: this.floorData.id,
        categoryId: this.selectedCategory.id,
        type: this.getLayerType(geofence.geofence.layer),
        color: this.cloneColor ? this.cloneColor : this.geofenceColor,
        // parentId: geofence.geofence.parent,
        coordinates: this.getCoordinates(geofence.geofence.layer),
        // expanded: false,
        ...(this.getLayerType(geofence.geofence.layer) == 'circle' && { radius: geofence.geofence.layer.getRadius() })
      }
      this.geofenceService.editGeofence(geofence.geofence.id, payload).subscribe(res => {
        geofence.geofence.name = this.geofenceName.trim() ? this.geofenceName : 'Default';
        this.geoListComp.currentGeofence.layer.setStyle({ color: this.geofenceColor }); // Apply selected color
        this.geoListComp.currentGeofence.layer.bindTooltip(this.geofenceName, {
          permanent: true,
        }).openPopup();
  
        if (geofence.category.id !== this.selectedCategory.id) {
          // Find or create the selected category
          let newCategory = this.geofences.find((cat: any) => cat.id === this.selectedCategory.id);
          if (!newCategory) {
            this.selectedCategory.geofences = [geofence.geofence]
            this.geofences.push(this.selectedCategory);
            // Find the existing category by geofence's current categoryId
            const currentCategory = this.geofences.find((cat: any) => cat.id === geofence.category.id);
            // Remove the geofence from the current category if it exists
            if (currentCategory) {
              if (geofence.geofence.parentId) {
                currentCategory.geofences.filter((parent: any) => parent.id !== geofence.geofence.parentId)
              }
              currentCategory.geofences = currentCategory.geofences.filter((g: any) => {
                g.children = g?.children.filter((g: any) =>
                  g.id !== geofence.geofence.id);
                if (g.id !== geofence.geofence.id) {
                  return g
                }
              })
              // If the category is now empty, remove it from the list
              if (currentCategory.geofences.length === 0) {
                this.geofences = this.geofences.filter((cat: any) => cat.id !== currentCategory.id);
              }
            }
          }
          else {
            // Find the existing category by geofence's current categoryId
            const currentCategory = this.geofences.find((cat: any) => cat.id === geofence.category.id);
            // Remove the geofence from the current category if it exists
            if (currentCategory) {
              currentCategory.geofences = currentCategory.geofences.filter((g: any) => {
                g.children = g?.children.filter((g: any) =>
                  g.id !== geofence.geofence.id);
                if (g.id !== geofence.geofence.id) {
                  return g
                }
              })
              // If the category is now empty, remove it from the list
              if (currentCategory.geofences.length === 0) {
                this.geofences = this.geofences.filter((cat: any) => cat.id !== currentCategory.id);
              }
              // Add the geofence to the selected category
              newCategory.geofences.push(geofence.geofence);
            }
          }
        }
        // }
        // Clear input fields after saving
        this.geoListComp.currentGeofence = null;
        this.geoListComp.currentGeofence = null;
        this.geofenceName = '';
        this.selectedCategory = this.categories[0];
        this.geofenceColor = '#3388ff';
        this.currentlyEditingGeofence = null;
        geofence.geofence.layer.editing.disable();
        
      })
    }
  }

  // Enable editing mode for the selected geofence
  enableGeofenceEditing(geofenceLayer: any, isCopy: boolean = false) {
    let originalRadius: any;
    let newRadius: any;
    let newLatLng: any;
    geofenceLayer.closeTooltip();

    // If another geofence is currently in edit mode, disable its editing
    if (this.currentlyEditingGeofence && this.currentlyEditingGeofence !== geofenceLayer) {
      this.currentlyEditingGeofence.editing.disable(); // Disable edit mode for the previous geofence
      this.map.removeControl(this.currentlyEditingGeofence.control); // Remove controls
    }

    // Custom logic for radius resizing
    L.Edit.Circle.include({
      _resize: function (e: any) {
        if (!originalRadius) {
          originalRadius = this._shape.getRadius();
        }
        newLatLng = this._shape.getLatLng();
        newRadius = this._shape.getLatLng().distanceTo(e);
        geofenceLayer.setRadius(newRadius);
        geofenceLayer.setLatLng(newLatLng);
      }
    });

    // Enable editing on the selected geofence
    this.currentlyEditingGeofence = geofenceLayer;
    geofenceLayer.editing.enable();
    if(isCopy){
      this.saveAndCancelControls(geofenceLayer, newLatLng, newRadius, originalRadius);
    }

    // Store the original state of the geofence for cancellation
    this.originalGeofenceState[geofenceLayer._leaflet_id] = {
      type: geofenceLayer instanceof L.Circle ? 'circle' : (geofenceLayer instanceof L.Rectangle ? 'rectangle' : 'polygon'),
      latlngs: geofenceLayer instanceof L.Circle ? [geofenceLayer.getLatLng()] : geofenceLayer.getLatLngs()
    };
  }

  saveAndCancelControls(geofenceLayer: any, newLatLng: any, newRadius: any, originalRadius: any) {
    // Create custom save/cancel button control
    const SaveCancelControl = L.Control.extend({
      onAdd: (map: any) => {
        const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control edit-control');
        const saveButton = L.DomUtil.create('button', '', container);
        saveButton.innerHTML = 'Save';
        saveButton.className = 'customBtn';
        const cancelButton = L.DomUtil.create('button', '', container);
        cancelButton.innerHTML = 'Cancel';
        cancelButton.className = 'customBtn';

        L.DomEvent.on(saveButton, 'click', () => {
          const payload: any = {
            name: geofenceLayer.name,
            floorId: this.floorData.id,
            categoryId: this.selectedCategory.id,
            type: this.getLayerType(geofenceLayer),
            color: geofenceLayer.options.color,
            // parentId: geofence.geofence.parent,
            coordinates: this.getCoordinates(geofenceLayer),
            // expanded: false,
            ...(this.getLayerType(geofenceLayer) == 'circle' && { radius: geofenceLayer.getRadius() })
          }
             this.geofenceService.addGeofence(payload).subscribe({
             next: async (res: any) =>{
              const copiedGeofence = await this.createGeofence(geofenceLayer, geofenceLayer.getBounds() || geofenceLayer.getLatLng(), null, 0, true);
              // Attach context menu and enable editing mode
              copiedGeofence.id = res.data.id;
              this.handleGeofenceCreation(copiedGeofence, this.selectedCategory, true);
              this.saveGeofenceChanges(geofenceLayer);

              if (newRadius) {
                geofenceLayer.setRadius(newRadius);
                geofenceLayer.setLatLng(newLatLng);
              }
              map.removeControl(this.currentlyEditingGeofence.control);
              this.currentlyEditingGeofence = null;
              this.cloneName = '';
              this.cloneColor = '';
             },
             error: (res: any) =>{
              geofenceLayer.editing.disable();
              this.cancelGeofenceChanges(geofenceLayer);
              if (originalRadius) {
                geofenceLayer.setRadius(originalRadius);
              }
              if (!(geofenceLayer instanceof L.Circle)) {
                geofenceLayer._tooltip.setLatLng(geofenceLayer.getCenter());
              }
              geofenceLayer.openTooltip();
              map.removeControl(this.currentlyEditingGeofence.control);
              this.currentlyEditingGeofence = null;
              this.cloneName = '';
              this.cloneColor = '';
             }
             })
        });

        L.DomEvent.on(cancelButton, 'click', () => {
          geofenceLayer.editing.disable();
          this.cancelGeofenceChanges(geofenceLayer);
          if (originalRadius) {
            geofenceLayer.setRadius(originalRadius);
          }
          if (!(geofenceLayer instanceof L.Circle)) {
            geofenceLayer._tooltip.setLatLng(geofenceLayer.getCenter());
          }
          geofenceLayer.openTooltip();
          map.removeControl(this.currentlyEditingGeofence.control);
          this.currentlyEditingGeofence = null;
        });

        return container;
      },
    });

    // Add the control to the map
    const control = new SaveCancelControl({ position: 'topright' }).addTo(this.map);
    this.currentlyEditingGeofence.control = control;
  }

  // Save geofence changes after editing
  saveGeofenceChanges(geofenceLayer: any) {
    // Handle saving updated geofence coordinates or properties
    geofenceLayer.editing.disable();
    if (!(geofenceLayer instanceof L.Circle)) {
      geofenceLayer._tooltip.setLatLng(geofenceLayer.getCenter());
    }
    geofenceLayer.openTooltip();
  }

  // Cancel geofence changes (revert to original)
  cancelGeofenceChanges(geofenceLayer: any) {
    // Revert the geofence to its original state
    const originalState = this.originalGeofenceState[geofenceLayer._leaflet_id];
    if (originalState) {
      if (originalState.type === 'circle') {
        geofenceLayer.setLatLng(originalState.latlngs[0]);
        geofenceLayer.openTooltip();
      } else if (originalState.type === 'rectangle' || originalState.type === 'polygon') {
        geofenceLayer.setLatLngs(originalState.latlngs);
        geofenceLayer.openTooltip();
      }
      // Remove the original state from the record
      delete this.originalGeofenceState[geofenceLayer._leaflet_id];
    }
  }

  getGeofence(floorId: number) {
    this.geofenceService.getGeofenceByFloor(floorId).subscribe({
      next: ((res: any) => {
        // this.geofences = [ ...res ];
        if(res.data.length){
          res.data.forEach((res: any) => {
            this.plotGeofenceOnFloor(res);
          });
        }

      }),
      error: ((error: any) => {
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Action Failed' })
      })
    })
  }


  saveGeofence(newGeofence: any, layer: any, parentGeofence: any) {
    const payload: any = {
      name: newGeofence.name,
      floorId: this.floorData.id,
      categoryId: this.selectedCategory.id,
      type: this.getLayerType(layer),
      color: this.cloneColor ? this.cloneColor : this.geofenceColor,
      parentId: parentGeofence?.id || null,
      coordinates: this.getCoordinates(layer),
      expanded: false,
      ...(this.getLayerType(layer) == 'circle' && { radius: layer.getRadius() })
    }
    return this.geofenceService.addGeofence(payload).pipe(
      map((res: any) => { 
        newGeofence.id = res.data.id;
        newGeofence.parentId = res.data.parentId;
        return newGeofence
      }),
      catchError((error: any) => {
        // Handle the error, e.g., log it or show a message
        console.error('Error adding geofence:', error);
        return of(newGeofence); // Return the geofence object even on error
      })
    ).toPromise();


  }

  plotGeofenceOnFloor(geofence: any) {
    let geofenceLayer: any;

    // Render different geofence types
    if (geofence.type === 'circle') {
      geofenceLayer = {
        layer: L.circle(geofence.coordinates[0], {
          radius: geofence.radius,
        }).addTo(this.map), layerType: 'circle',
        geoId: geofence.id,
        geoColor: geofence.color
        
      }
    } else if (geofence.type === 'polygon') {
      geofenceLayer = {
        layer: L.polygon(geofence.coordinates, {
        }).addTo(this.map), layerType: 'polygon',
        geoId: geofence.id,
        geoColor: geofence.color
      }

    } else if (geofence.type === 'rectangle') {
      geofenceLayer = {
        layer: L.rectangle(geofence.coordinates, {
        }).addTo(this.map), layerType: 'rectangle',
        geoId: geofence.id,
        geoColor: geofence.color
      }

    }
    else if (geofence.type === 'polyline') {
      geofenceLayer = {
        layer: L.polyline(geofence.coordinates, {
        }).addTo(this.map), layerType: 'polyline',
        geoColor: geofence.color,
        geoId: geofence.id
      }

    }

    // Set selected category and add to listing
    // this.cloneColor = geofence.color
    this.geofenceName = geofence.name
    this.plottingGeofence = true;
    // this.selectedCategory["id"] = geofence.categoryId;
    const category = this.categories.find((cat: any) => cat.id == geofence.categoryId);
    this.geoFenceListing(geofenceLayer, category);
  }

}

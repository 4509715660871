import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionButtons } from 'src/app/feature/settings/components/geofencing/geofence.interface';

@Component({
  selector: 'app-geofence-listing',
  templateUrl: './geofence-listing.component.html',
  styleUrls: ['./geofence-listing.component.scss']
})
export class GeofenceListingComponent implements OnInit {
  @Input() geofences: any
  @Input() actionButtons!: ActionButtons;
  
  @Output() highlight: EventEmitter<any> = new EventEmitter();
  @Output() onEditGeofence: EventEmitter<any> = new EventEmitter();
  @Output() deleteSelectedGeofence: EventEmitter<any> = new EventEmitter();
  @Output() selectedGeofence: EventEmitter<any> = new EventEmitter();


  highlightedGeofence: any;
  currentGeofence: any | null = null;
  drawnItems!: L.FeatureGroup;
  selectedCategory: any;
  geoToggleExpand: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleExpand(geofence: any) {
    geofence.expanded = !geofence.expanded;
  }

  highlightGeofence(geofence: any) {
    this.highlight.emit(geofence);
    this.highlightedGeofence = geofence;
  }

  editGeofence(geofence: any, category: any) {
    // this.currentGeofence = geofence;
    const editConfig = {
      geofence: geofence,
      category: category
    }
    this.onEditGeofence.emit(editConfig);
  }

  deleteGeofence(geofence: any, event: Event, categoryId: any) {
    let data = { geofence: geofence, categoryId: categoryId }
    this.deleteSelectedGeofence.emit(data);
  }


  isSelectedCategory(geofence: any): boolean {
    return geofence?.id === this.selectedCategory?.id;
  }


  toggleChange(data: any, event: any) {
    let geofence = {
      geofence: data,
      enable: event.checked
    }
    this.selectedGeofence.emit(geofence)
  }
}

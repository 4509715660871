import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Tree } from 'primeng/tree';
import { Device } from 'src/app/feature/settings/components/facilities/facilityModels/facilityModels';
@Component({
  selector: 'app-facility-tree',
  templateUrl: './facility-tree.component.html',
  styleUrls: ['./facility-tree.component.scss']
})
export class FacilityTreeComponent {
  @ViewChild('tree') treeElement!: Tree
  @Input() selection: any = 'single'
  @Input() treeConfig!: TreeNode[]
  @Input() menuConfig: Device[] = [];
  @Input() filtering: boolean = true;
  @Output() selectedNode = new EventEmitter<any>();
  @Output() devicesList = new EventEmitter<Device[]>()
  selectedNodes: TreeNode[] = [];

  selectionChange(event: any, expand: any) {

    let nodes: any
    if (event?.node?.label == 'Campus') {
      expand.expanded = !expand.expanded
      return;
    }
    if (this.selection == 'single') {
      nodes = event
    }
    else {
      nodes = this.selectedNodes
    }
    this.selectedNode.next(nodes);
  }

  expandTreeNodes(expandConfig: any){
    this.treeConfig.forEach(node => {
      this.expandRecursive(expandConfig, node, true);
    });
  }

  expandRecursive(expandConfig: any, node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
  
    if (node.children) {
      node.children.forEach((childNode: any) => {
        // Determine expansion state based on child node's level and ID
        const shouldExpand = 
          (childNode.level === 'facility' && childNode.id === expandConfig.facilityId) ||
          (childNode.level === 'building' && childNode.id === expandConfig.buildingId);
        
        // Recursively apply expansion
        this.expandRecursive(expandConfig, childNode, shouldExpand);
      });
    }
  }

  getFloorDevices(event: any) {
    this.devicesList.next(event);
  }

  getIconPath(node: any): string {
    switch (node.level) {
      case 'facility':
        return node.facilityIcon;
      case 'floor':
        return node.floorIcon;
      default:
        return node.buildingIcon;
    }
  }
}


<div class="mapWrapper">
    <google-map #mapContainer [center]="center" [zoom]="zoom"
        (mapClick)="moveMap($event)" (mapMousemove)="move($event)">
            <map-marker
            *ngFor="let marker of markers; trackBy: markerLabel"
            #markerRef="mapMarker"
            [position]="marker.position"
            [title]="marker.title"
            [options]="{icon :marker.icon}"
            [label]="marker.label"
            (mapClick)="onMarkerClick(marker)"
            (mapMouseover)="openInfoWindow(markerRef, marker)"
            (mapMouseout)="closeInfoWindow()"
          ></map-marker>
        <map-info-window #infoWindow><div [innerHtml]="infoContent"></div></map-info-window>
    </google-map>
</div>
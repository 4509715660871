<div class="campusSection" *ngIf="viewInitialize">
    <div class="campSidebar itemList" [ngClass]="{'toggle': groupsConfig?.length, 'hideFacilityList': hideGroupTree}">
        <div class="filter-sidebar-summary">
            <div class="filter-sidebar-total">{{ devicesList?.length }} items</div>
            <a type="button" class="clearBtn" (click)="clearGroupFilter()">
                <i class="pi pi-trash"></i>Clear
            </a>
        </div>
        <div class="filter-listingmodule">
            <ng-container *ngIf="groupsConfig?.length">
                <app-group-filter (selectedGroup)="getSelectedGroupItem($event)" [groupsList]="groupsConfig"></app-group-filter>
            </ng-container>
            <ng-container *ngIf="facilityResponse?.length">
                <app-facility-tree [filtering]="false" [treeConfig]="facilityResponse" (selectedNode)="getSelectedNode($event)"></app-facility-tree>
            </ng-container>
            <ng-container *ngIf="selectedFloorId && geofences.length">
                <hr>
                <app-geofence-listing 
                [actionButtons]="actionButtons"
                [geofences]="geofences"
                (highlight)="highlightGeofence($event)"
                (selectedGeofence)="enableDisableGeofence($event)" >
                </app-geofence-listing>
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="!hideFacilityTree">
        <div class="campSidebar deviceListContainer" [ngClass]="{'toggle': selectedFloorDevices.length, 'hideFacilityList': hideFacilityTree}">
            <div class="deviceListing">
                <app-device-list-filter [deviceItems]="deviceFilterList" (selectedDevice)="filteredDeviceItem($event)"></app-device-list-filter>
                <app-device-penal (highlightSelectedDeviceItem)="highlightSelectedDeviceItem($event)" (selectedDevice)="getActiveDevices($event)" [devicesList]="devicePenalList" [highlightDeviceItem]="highlightDeviceItem"></app-device-penal>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedTreeNode && selectedTreeNode[0]?.level === 'floor'; else googleMapTemplate">
        <app-map-view class="campusMapView " [devices]="devicesList" [mapConfig]="selectedTreeNode" (isHideTreeView)="isHideTreeView($event)" (isHideGroupView)="hideGroupTree = !hideGroupTree;" (resetMap)="resetMapView()" ></app-map-view>
    </ng-container>
    
    <ng-template #googleMapTemplate>
        <app-google-map [highlightMarker]="highlightMarker" (isHideTreeView)="isHideTreeView($event)" (resetMap)="resetMapView()" (markerSelection)="markerSelection($event)"  (isHideGroupView)="hideGroupTree = !hideGroupTree"></app-google-map>
    </ng-template>
</div>
<div [hidden]="!loader">
    <p-progressSpinner  ariaLabel="loading"></p-progressSpinner>
</div>
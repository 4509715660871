<div class="facilityDetails">
    <div class="facilityHead">
        <h2>
            <!-- <span class="facilityIcon">
                <img src="assets/images/web_stories-icon.svg" alt="Facility Image" />
            </span> -->
            {{selectedTab}}
        </h2>
        <div class="actionBtnGroup" (click)="getWidgetDetail()">
            <app-button-field 
                [class.active]="selectedTab === 'Facilities'" 
                (click)="tabChange('Facilities')" 
                class="current">
                Facilities <span class="pi pi-clone"></span>
            </app-button-field>
            <app-button-field 
                [class.active]="selectedTab === 'Buildings'" 
                (click)="tabChange('Buildings')">
                Buildings <span class="pi pi-clone"></span>
            </app-button-field>
            <app-button-field 
                [class.active]="selectedTab === 'Floors/Area'" 
                (click)="tabChange('Floors/Area')">
                Floors/Area <span class="pi pi-clone"></span>
            </app-button-field>
        </div>
    </div>

    <div *ngIf="!showGeofence || selectedTab == 'Facilities' || selectedTab == 'Buildings'">
        <div *ngIf="!showFormSection" class="addFacilitiesHead">
            <app-button-field (click)="createForm()">{{btnText}}</app-button-field>
        </div>
        
        <div *ngIf="showFormSection" class="facilityList facilitiesModuleWrapper">
            <div class="closeElement">
                <i (click)="close()" class="pi pi-times"></i>
            </div>
            <app-dynamic-form-builder #form class="facilitiesModule" [config]="formConfig"></app-dynamic-form-builder>
            
            <div *ngIf="imageEle">
                <div *ngIf="isRegistrationView; else imageUpload">
                    <app-map-view #mapComp [screenView]="'facility'" [tableConfig]="deviceConfig" [mapConfig]="selectedFloor" class="mapViewWrapper"></app-map-view>
                </div>
                <ng-template #imageUpload>
                    <app-image-upload (imageFile)="getImageFile($event)"></app-image-upload>
                </ng-template>
            </div>
            
            <div class="docSaveBtn">
                <app-button-field *ngIf="imageFile ||  editImages && isDeviceRegister == ''" (click)="editImage()">Edit image</app-button-field>
                <ng-container *ngIf="selectedTab === 'Floors/Area' && editRowData?.id">
                    <app-button-field 
                        [class.deviceBtn]="isDeviceRegister == 'device'" 
                        [isDisable]="isDeviceRegister == 'gateway'" 
                        (click)="isDeviceRegister !== 'gateway' ? onRegistration('device') : null">
                        Register Devices
                    </app-button-field>
                    <app-button-field 
                        [class.deviceBtn]="isDeviceRegister == 'gateway'" 
                        [isDisable]="isDeviceRegister == 'device'" 
                        (click)="isDeviceRegister !== 'device' ? onRegistration('gateway') : null">
                        Register Gateways
                    </app-button-field>
                </ng-container>
                
                <app-button-field (click)="onSaveFacilityDetails(form?.form)">
                    {{ editRowData?.id ? 'Update' : 'Save' }}
                </app-button-field>
            </div>
        </div>
        
        <app-widgets 
            [tableConfig]="tableConfig" 
            [selectedTab]="selectedTab" 
            (deleteFacilityDetail)="onDeleteWidgetDetail($event)" 
            (editFacilityDetail)="onEditDetail($event)"
            (openGeofence)="openGeofence($event)">
        </app-widgets>
    </div>
    <div *ngIf="(showGeofence && selectedTab == 'Floors/Area')">
        <app-geofencing [floorData]="floorData" (backToFloor)="backToFloor($event)"></app-geofencing>
    </div>

</div>
<div [hidden]="!loader">
    <p-progressSpinner  ariaLabel="loading"></p-progressSpinner>
</div>

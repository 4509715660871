export const ApiEndpoints = {
  Auth: {
    Login: '/auth/login',
    RefreshApi: '/auth/refresh',
  },
  settings: {
    Facilities: {
     AddFacility: '/facilities/add',
     AllFacilities: '/facilities',
     EditFacility: '/facilities/edit',
     GetImage: 'facilities/base64'
    },
    Markers: {
      Makers: '/marker',
      GetAllIcons: '/marker/markerIcons',
      uploadMarker: '/marker/uploadMarker'
    },
    Devices: {
      UpdateDevice: '/device/status',
      AllDevices: '/device',
      linkToFloor: '/device/linkToFloor',
      unlinkFromFloor: '/device/unlinkFromFloor',
      csvUpload: '/device/csvUpload'
    },
    Buildings: {
    //  AddFacility: '/buildings/add',
     AllBuildings: '/buildings',
     buildingById: '/buildings/buildingById',
    //  EditFacility: '/buildings/edit'
    },
    Floors: {
    //  AddFacility: '/buildings/add',
     AllFloors: '/floors',
     FloorById: '/floors/floorById',
    //  EditFloor: '/floors/edit'
    },
    Gateways: {
      AddGateways: '/gateway/save-gateway',
      GetGateways: '/gateway',
      EditGateways: '/gateway/edit-gateway',
      linkToFloor: '/gateway/linkToFloor'
    },
    Groups: {
      allGroups: '/device/all-group',
      Groups: '/device/group'
    },
    DeviceTypes: {
      DeviceType: '/device/type',
    },
    Geofence: {
      getCategories: '/campus/category',
      addCategory: '/campus/category/add',
      editCategory: '/campus/category/edit',
      deleteCategory: '/campus/category/',
      getGeofences: '/geofence',
      getGeofenceByFloor: '/geofence/floor/',
      addGeofence: '/geofence',
      deleteGeofence: '/geofence/',
      editGeofence: '/geofence/',
    }
  },
  Campus: {
    CampusFacilities: '/facilities/campus',
    DeviceSummary: '/device/summary',
    allDevice: '/device/all',
    GetGroups: '/device/group',
    MapConfig: '/facilities/savedLocation'
  }
}
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { DomSanitizer } from '@angular/platform-browser';
import { mapConfig } from 'src/app/feature/campus/models/campus.model';
import { CampusService } from 'src/app/feature/campus/services/campus.service';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent {

  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild('mapContainer') mapContainer!: any;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;

  @Input() highlightMarker: any;

  devices: any[] = [];
  selectedTreeNode: any

  @Output() isHideTreeView = new EventEmitter<any>();
  @Output() isHideTableView = new EventEmitter<any>();
  @Output() isHideGroupView = new EventEmitter<any>();
  @Output() resetMap = new EventEmitter<any>();
  @Output() markerSelection = new EventEmitter<any>();

  infoContent: any = '';
  markers: any[] = [];
  center!: google.maps.LatLngLiteral

  zoom = 15;
  layers: any[] = [];
  imageOverlay: any;
  googleOriginal: any;
  deviceIds: any[] = [];
  zoomAnimation: any;
  display!: google.maps.LatLngLiteral;
  svgDataUrl: any;
  markerCluster: any;
  lastZoomLevel!: number;
  mapViewType!: string | undefined;
  selectedMode: string = '';

  setCoordinates!: any;
  locationIdsForLastView: any = [];
  saveLastViewControl!: HTMLDivElement;
  svgIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">
    
    <defs>
    </defs>
    <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
      <path d="M 45 90 C 30.086 71.757 15.174 46.299 15.174 29.826 S 28.527 0 45 0 s 29.826 13.353 29.826 29.826 S 59.914 71.757 45 90 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(220,32,40); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
      <circle cx="45" cy="29.380000000000003" r="13.5" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
      <path d="M 48.596 5.375 C 33.355 5.375 21 17.73 21 32.97 c 0 1.584 0.141 3.135 0.397 4.646 C 20.496 35.035 20 32.264 20 29.375 c 0 -13.807 11.193 -25 25 -25 c 2.889 0 5.661 0.496 8.242 1.397 C 51.731 5.516 50.18 5.375 48.596 5.375 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(231,77,70); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
    </g>
    </svg>`

  constructor(private sanitizer: DomSanitizer, private campusService: CampusService) {
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.highlightMarker) {
      this.zoomToMarker(this.highlightMarker._id);
    }
  }

  updateMarkers(): void {
  }

  CreateCampusLocations() {
    this.svgDataUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(this.svgIcon)}`;
    this.markers = [];
    this.locationIdsForLastView = []
    if (this.selectedTreeNode) {
      this.selectedMode = this.selectedTreeNode[0]?.level;
      for (let config of this.selectedTreeNode) {
        this.locationIdsForLastView.push(config.id);
        if (config.latitude && config.longitude) {
          const markerConfig: any = this.createMarker({
            icon: {
              url: this.svgDataUrl,
              scaledSize: { width: 30, height: 30 } // Adjust the size as needed
            },
            markerName: config?.name || config?.title || config?.label,
            facilityId: config.facilityId,
            _id: config.id || config.vehicle_id,
            infoWindow: true,
            coordinates: { lat: +(config?.gpsMessage?.lat || config?.latitude || config?.lat), lng: +(config?.gpsMessage?.lng || config?.longitude || config?.lng) },
            popupContent: config.label
          });
          this.markers.push(markerConfig);
        }
      }
    }
  }

  createDevices() {
    this.svgDataUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(this.svgIcon)}`;
    this.markers = [];
    this.locationIdsForLastView = [];
    for (let config of this.devices) {
      this.selectedMode = 'groupSelection'
      const deviceId = config?.vehicle_id || config?.id
      const activeIdsCheck = JSON.parse(localStorage.getItem('activeDevices') || '');
      if (activeIdsCheck.includes(deviceId)) {
        this.locationIdsForLastView.push(deviceId);
        const markerConfig: any = this.createMarker({
          markerName: config.name || config.title,
          icon: {
            url: this.svgDataUrl,
            scaledSize: { width: 30, height: 30 } // Adjust the size as needed
          },
          _id: config.vehicle_id || config.id,
          infoWindow: true,
          coordinates: { lat: +(config?.latitude || config?.lat), lng: +(config?.longitude || config?.lng) },
          popupContent: config?.label || ''
        });
        if (markerConfig.position.lat && markerConfig.position.lng) {
          this.markers.push(markerConfig);
        }
      }
    }
  }

  zoomToMarker(id: number) {
    // Find the marker with the given id
    const location = this.markers.find(loc => loc._id === id);
    if (location) {
      location.label.className = 'map__marker-label map__marker-label-highlight'
      this.map.panTo(location.position);
      setTimeout(() => {
        location.label.className = 'map__marker-label';
        this.highlightMarker = null; // Clear the highlightMarker if needed
      }, 1000); // Delay time in milliseconds
    }
  }

  markerLabel(index: any, item: any) {
    return item.label.className
  }


  zoomToMarkers(): void {
    const bounds = new google.maps.LatLngBounds();
    if (this.markers.length === 1) {
      // If there is only one marker, set the zoom level to 5
      const singleMarker = this.markers[0];
      this.mapContainer?.googleMap?.setCenter(singleMarker.position);
        this.mapContainer.googleMap.setZoom(this.zoom);

    } else {
      // Extend bounds for all markers
      this.markers.forEach(marker => {
        bounds.extend(marker.position);
      });

      setTimeout(() => {
        this.mapContainer.googleMap.fitBounds(bounds);
      }, 10);
    }
  }

  createMarker(options: any) {
    return {
      markerName: options.markerName,
      _id: options._id,
      position: options.coordinates,
      title: options.popupContent,
      facilityId: options.facilityId,
      icon: {
        url: this.svgDataUrl,
        scaledSize: { width: 30, height: 30 } // Adjust the size as needed
      },
      draggable: options.draggable || false,
      label: this.getMarkerLabel(options, this.highlightMarker),
      content: options.popupContent,
      infoWindow: options.infoWindow
    };
  }

  onMarkerClick(marker: any){
   if(this.selectedTreeNode.length > 1) {
     this.mapContainer?.googleMap?.setCenter(marker.position);
     this.mapContainer.googleMap.setZoom(20);
     this.markerSelection.emit({facilityId : marker.facilityId, buildingId: marker._id});
   }
  }

  openInfoWindow(marker: MapMarker, markerData: any) {
    if (!markerData.infoWindow) return;
    const { markerName, position }: any = markerData;
    this.infoContent = this.sanitizer.bypassSecurityTrustHtml(`
      <div class="addressNotification">
        <strong>Asset Name:</strong> ${markerName}<br>
        <strong>Location:</strong> ${position.lat}, ${position.lng}
      </div>
    `);
    this.infoWindow.open(marker);
  }

  closeInfoWindow() {
    this.infoWindow.close();
  }

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = event.latLng.toJSON();
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  ngAfterViewInit() {
    if (this.map?.googleMap) {
      this.addCustomControls(this.map.googleMap);
    }
    this.setLastMapView();
  }

  setLastMapView() {
    if (sessionStorage.getItem("mapType")) {
      let mapType = sessionStorage.getItem("mapType") || 'roadmap';
      if (mapType == "hybrid" || mapType == "roadmap" || mapType == "terrain" || mapType == "satellite") {
        this.map.googleMap?.setMapTypeId(mapType);
      } else {
        this.map.googleMap?.setMapTypeId("hybrid");
      }
    }
    if (sessionStorage.getItem("zoom")) {
      let LastZoomLevel = JSON.parse(sessionStorage.getItem("zoom") || '');
      this.map.googleMap?.setZoom(LastZoomLevel);
    }
    if (sessionStorage.getItem("center")) {
      let positions = JSON.parse(sessionStorage.getItem("center") || '');
      const lastLocation = { lat: +positions.lat, lng: +positions.lng };
      this.map.googleMap?.setCenter(lastLocation);
    }
  }

  addCustomControls(map: google.maps.Map) {
    const hideTreeViewControlDiv = document.createElement('div');
    this.HideTreeViewControl(hideTreeViewControlDiv, map);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(hideTreeViewControlDiv);

    const hideGroupViewControlDiv = document.createElement('div');
    this.HideGroupViewControl(hideGroupViewControlDiv, map);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(hideGroupViewControlDiv);

    this.saveLastViewControl = document.createElement('div');
    this.saveMapConfig(this.saveLastViewControl, map);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.saveLastViewControl);

    const resetMapControlDiv = document.createElement('div');
    this.ResetMapControl(resetMapControlDiv, map);
    map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(resetMapControlDiv);

  }

  HideTreeViewControl(controlDiv: HTMLElement, map: google.maps.Map) {
    const controlUI = document.createElement('div');
    controlUI.title = 'Click to hide/show tree view';
    controlDiv.appendChild(controlUI);
    const controlText = document.createElement('div');
    let icon = 'pi-chevron-left';
    controlText.innerHTML = `<div class="hideTreeView"><i class="pi ${icon}"></i></div>`;
    controlUI.appendChild(controlText);
    controlUI.addEventListener('click', () => {
      this.isHideTreeView.emit();
      icon = icon === 'pi-chevron-right' ? 'pi-chevron-left' : 'pi-chevron-right';
      controlText.innerHTML = `<div class="hideTreeView"><i class="pi ${icon}"></i></div>`;
      setTimeout(() => {
        google.maps.event.trigger(map, 'resize');
      }, 400);
    });
  }

  HideGroupViewControl(controlDiv: HTMLElement, map: google.maps.Map) {
    const controlUI = document.createElement('div');
    controlUI.title = 'Click to hide/show group view';
    controlDiv.appendChild(controlUI);
    const controlText = document.createElement('div');
    let icon = 'pi-angle-double-left';
    controlText.innerHTML = `<div class="hideGroupView"><i class="pi ${icon}"></i></div>`;
    controlUI.appendChild(controlText);
    controlUI.addEventListener('click', () => {
      this.isHideGroupView.emit();
      icon = icon === 'pi-angle-double-right' ? 'pi-angle-double-left' : 'pi-angle-double-right';
      controlText.innerHTML = `<div class="hideGroupView"><i class="pi ${icon}"></i></div>`;
      setTimeout(() => {
        google.maps.event.trigger(map, 'resize');
      }, 400);
    });
  }

  ResetMapControl(controlDiv: HTMLElement, map: google.maps.Map) {
    const controlUI = document.createElement('div');
    controlUI.className = 'leaflet-bar leaflet-control';
    controlUI.title = 'Click to reset the map';
    controlDiv.appendChild(controlUI);
    const controlText = document.createElement('div');
    controlText.innerHTML = '<div class="resetMap"><i class="pi pi-undo"></i></div>';
    controlUI.appendChild(controlText);
    controlUI.addEventListener('click', () => {
      this.resetMap.emit();
      if (this.markers?.length) {
        this.zoomToMarkers();
      }
      setTimeout(() => {
        google.maps.event.trigger(map, 'resize');
      }, 400);
    });
  }

  //custom map control for saving map config
  saveMapConfig(controlDiv: HTMLElement, map: google.maps.Map) {
    const controlUI = document.createElement('div');
    controlUI.className = 'leaflet-bar leaflet-control';
    controlUI.title = 'Set default view';
    controlDiv.appendChild(controlUI);
    const controlText = document.createElement('div');
    controlText.innerHTML = '<div class="saveLocation"><i class="pi pi-thumbtack"></i></div>';
    controlUI.appendChild(controlText);
    controlUI.addEventListener('click', () => {
      sessionStorage.setItem('locationIdsForLastView', JSON.stringify(this.locationIdsForLastView))
      this.lastZoomLevel = this.map.googleMap?.getZoom() || 1;
      this.setCoordinates = this.map.getCenter()?.toJSON() || { lat: 0, lng: 0 };
      const mapViewType = this.map.googleMap?.getMapTypeId() || 'satellite';
      sessionStorage.setItem('mapType', mapViewType)

      let payLoad: mapConfig = {
        zoom: this.lastZoomLevel,
        center: this.setCoordinates,
        locationIdsForLastView: this.locationIdsForLastView,
        mapType: mapViewType,
        selectedMode: this.selectedMode
      }
      sessionStorage.setItem('zoom', JSON.stringify(this.lastZoomLevel));
      sessionStorage.setItem('center', JSON.stringify(this.setCoordinates));
      sessionStorage.setItem('selectedMode', this.selectedMode);
      this.saveMapView(payLoad);

    });
  }

  saveMapView(payLoad: mapConfig) {
    this.campusService.saveMapConfig(payLoad).subscribe(res => {

    })
  }

  // In your component TypeScript file
  getMarkerLabel(marker: any, highlightMarker: any): any {
    return {
      text: marker.markerName,
      color: '#000',
      className: 'map__marker-label ' + (marker?.vehicleName || ''),
      fontSize: '14px',
    };
  }

}

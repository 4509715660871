<p-table *ngIf="!loader" [columns]="tableConfig?.header" [rows]="20" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true" 
[paginator]="true" [value]="tableConfig?.data" [tableStyle]="{ 'min-width': '50rem' }">
  <ng-template pTemplate="header" let-columns>
      <tr>
          <th *ngFor="let col of columns">
              {{ col.header }}
          </th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
          <td *ngFor="let col of columns">
              <ng-container [ngSwitch]="col.field">
                  <ng-container *ngSwitchCase="'image'">
                      <img *ngIf="rowData[col.field] != null" [src]="rowData[col.field]" alt="facilityImage" width="50" height="50">
                      <img *ngIf="rowData[col.field] == null" [src]="defaultImg" alt="facilityImage" width="50" height="50">
                  </ng-container>
                  <ng-container *ngSwitchCase="'action'">
                      <div class="fInfo">
                          <div class="fActionBtnWraper">
                            <div *ngIf="rowData?.action?.geofence" class="geofence" (click)="onGeofence(rowData.item)">
                                <span class="pi pi-map"></span>Geofence
                            </div>
                            <div class="editAction" [class.disable]="!rowData?.action?.edit" (click)="onEdit(rowData.item)">
                                <span [ngClass]="selectedRowIndex == rowData?.item?.id ? 'pi pi-times' : 'pi pi-pencil'"></span>
                                {{ selectedRowIndex == rowData?.item?.id ? 'Cancel' : 'Edit'}}
                            </div>
                            <div class="delAction" [class.disable]="!rowData?.action?.delete" (click)="onDelete(rowData.item.id)">
                                <span class="pi pi-trash"></span>Delete
                            </div>
                          </div>
                      </div>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                      {{ rowData[col.field] }}
                  </ng-container>
              </ng-container>
          </td>
      </tr>
  </ng-template>
</p-table>
